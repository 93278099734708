<template>
    <div>
        <div class="relation-course" v-if="relationData.length>0">
            <div class="item" v-for="(item,index) in relationData" :key="index">
                <router-link :to="{path:`/courseDetail/${item.id}`}">
                    <div class="img">
                        <img :src="item.cover_image_url" alt="">
                    </div>
                    <div class="course-desc">
                        <div class="course-title">
                            <span>{{ translateTag(item.category_id).abbr }}</span>
                            {{ item.name }}
                        </div>
                        <div class="course-time">
                            <span v-if="item.class_type === ENUM.CLASS_TYPE.PRIVATE">即时上课</span>
                            <template v-else-if="item.course_mode === ENUM.COURSE_MODEL.VIDEO">
                                <span >有效期{{item.valid_days}}天</span>
                            </template>
                            <template v-else-if="item.course_mode === ENUM.COURSE_MODEL.LIVE">
                                <span >开课时间 {{formatMoment(item.started_at, 'YYYY-MM-DD')}}</span>
                            </template>
                        </div>
                        <div class="course-price">
                            ￥{{ item.guide_price }}
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { formatMoment, translateTag } from '@/utils/utils'
import * as ENUM from '@/enums/API'

export default {
    name: 'RelationCourse',
    props: {
        relationData: {
            type: Array,
            default: () => {
                []
            }
        }
    },
    data () {
        return {
            ENUM,
        }
    },
    methods: {
        formatMoment,
        translateTag
    }
}
</script>

<style lang="less" scoped>
/*@import '../../assets/style/lib-base.less';*/

.relation-course {
    & > .item {
        & > a {
            width: 100%;
            height: 138px;
            border-bottom: 1px solid #e6e6e6;
            display: flex;
            align-items: center;

            & > .img {
                width: 176px;
                height: 99px;
                margin-right: 20px;
                border-radius: 3px;
                overflow: hidden;

                & > img {
                    width: 100%;
                }
            }

            & > .course-desc {
                text-align: left;

                & > .course-title {
                    display: flex;
                    align-items: center;

                    & > span {
                        background: #667482;
                        color: #fff;
                        padding: 2px 4px;
                        margin-right: 8px;
                        font-size: @fontSizeCon;
                    }

                    font-size: @fontSizeTitle;
                    color: #333;
                    font-weight: bold;
                }


                & > .course-time {
                    font-size: @fontSizeSubTitle;
                    color: #5A5C63;
                    margin: 6px 0 30px;
                    .course-tag{
                        border-radius: 2px;
                        padding: 0 2px;
                        background: #DEF7E6;
                        color: #20A84C;
                    }
                }

                & > .course-price {
                    color: @papaRed;
                    font-size: @fontSizeTitle;
                    font-weight: 600;
                }
            }
        }
    }
}
</style>
