import * as Api from '@/api/course/course.js'
import ClassSchedule from '@/components/ClassSchedule/ClassSchedule.vue'
import RelationCourse from '@/components/RelationCourse/RelationCourse.vue'
import TCPlayer from '@/components/TCPlayer/TCPlayer.vue'
import Comment from '@/components/Comment/Comment.vue'
import CourseMaterial from '@/views/User/LiveDetail/LiveClassDetail/Materials.vue'
import store from '@/store'
import {
  classesCourseWatchDuration,
  courseAnalystic,
} from '@/api/common/common'
import { ANALYTIC_TYPE, COURSE_MODEL } from '@/enums/API'
import { mapActions, mapState } from 'vuex'
import { courseWatch } from '@/api/course/course.js'

export default {
  components: {
    ClassSchedule,
    RelationCourse,
    Comment,
    CourseMaterial,
    TCPlayer,
  },
  data () {
    return {
      activeCourse: {},
      activeTeacher: {},
      activeName: 'detail',
      courseDetailData: [],
      classSchedule: [],
      teacherLevel: [],
      leastQty: 1,
      unitPrice: 1,
      relationCourse: [],
      maxBuyNum: 999,
      buyNum: 0,
      comment: [],
      commentPagination: {},
      videoUrl: '',
      currentCourseId: '',
      CourseMaterialData: [],
      currentCourse: {},
      isBuy: 1, // type= 1 未购买  type=2  购买后,
      btnName: '立即报名',
      playerOptions: {},
      timeCount: 1,
      timeInterval: null,
      playFlag: true, // 是否统计点击播放
      intervalObj: null,
      videoDruation: 0,
      currentVideoFid: '',
      COURSE_MODEL,
      currentPlanId: '',
    }
  },
  props: ['courseId', 'planId'],
  mounted () {
    if (this.$route.path.indexOf('user/pastCourseDetail') > 0) {
      this.isBuy = 2
    }
    this.getCourseDetail()
    if (store.state.user.authorization.access_token) {
      this.getIsEnroll()
    }
    if (this.isBuy === 2) {
      this.handleClick({ name: 'schedule' })
      this.activeName = 'schedule'
    }
  },
  activated () {
    // this.getCourseData()
  },
  computed: {
    showPrice: function () {
      return this.buyNum * this.unitPrice
    },
    courseDetailType: function () {
      if (this.$route.path.indexOf('user/pastCourseDetail') > 0) {
        return 2
      } else {
        return 1
      }
    },

    postPic () {
      if (this.courseDesc && this.courseDesc.course) {
        return this.courseDesc.course.cover_image_url
      } else {
        return ''
      }
    },
    videoWatchRecords () {
      return (
          (sessionStorage[ 'videoWatchRecords' + this.courseId ] &&
              JSON.parse(sessionStorage[ 'videoWatchRecords' + this.courseId ])) ||
          {}
      )
    },
    ...mapState({
      courseDesc: ( state ) => state.course.currentCourseDesc,
    }),
  },
  methods: {
    getCourseDetail () {
      if (this.courseDetailType == 1) {
        Api.courseDetail(
            '',
            ( res ) => {
              this.courseDetailData = res
            },
            '',
            this.courseId,
            'v1'
        )
      } else {
        // this.getCourseDesc()
        Api.classCourse(
            '',
            ( res ) => {
              this.courseDetailData = res
              if (localStorage[ this.courseId ]) {
                let localCourse = JSON.parse(localStorage[ this.courseId ])
                this.videoUrl = localCourse.video_url
                this.currentCourse = localCourse
                this.currentCourseId = localCourse.id
                this.currentVideoFid = localCourse.video_id
              } else {
                this.videoUrl = res[ 0 ].children[ 0 ].video_url
                this.currentCourse = res[ 0 ].children[ 0 ]
                this.currentCourseId = res[ 0 ].children[ 0 ].id
                this.currentVideoFid = res[ 0 ].children[ 0 ].video_id
              }

              sessionStorage[ 'videoWatchRecords' + this.courseId ] =
                  JSON.stringify({})
              // todo 免费视频跳知乎
              this.$nextTick(() => {
                this.$refs.childplayer.initPlay(this.currentVideoFid)
              })
            },
            this.planId
        )
      }
    },
    getIsEnroll () {
      Api.pastCourseDetail(
          'get',
          '',
          ( res ) => {
            if (res.is_enroll) {
              this.btnName = '立即学习'
              this.currentPlanId = res.course_plan_id
            } else {
              if (this.isBuy === 2) {
                this.$router.push({
                  name: 'pastCourseDetail',
                  params: {
                    courseId: this.$route.params.id,
                  },
                })
              } else {
                this.btnName = '立即报名'
              }
            }
          },
          this.courseId
      )
    },
    // getCourseDesc () {
    //     Api.userCourseDesc('get', '', res => {
    //         this.courseDesc = res
    //     }, '', this.courseId,)
    // },
    handleChange ( item ) {
      this.buyNum = item
    },

    // tab 切换
    handleClick ( item ) {
      switch (item.name) {
        case 'schedule':
          Api.coursePlanApi(this.courseId, ( res ) => {
            this.classSchedule = res
          })
          break
        case 'relation':
          Api.relationCourseApi(this.courseId, ( res ) => {
            this.relationCourse = res
          })
          break
        case 'comment':
          break
        case 'materials':
          Api.courseMaterials(this.planId, ( res ) => {
            this.CourseMaterialData = res
          })
      }
    },
    subComment ( data ) {
      let params = {
        course_id: this.courseId,
        star: data.star,
        content: data.content,
      }
      Api.subCourseCommentApi(params, () => {
      })
    },
    changeVideo ( item ) {
      let oldCourseId = this.currentCourseId
      // todo 免费视频跳知乎
      const lastSecond = Math.floor(this.$refs.childplayer.getCurrentTime())
      localStorage[ this.courseId ] = JSON.stringify(item)
      // let  = {}
      let watchRecordsObj =
          (sessionStorage[ 'videoWatchRecords' + this.courseId ] &&
              JSON.parse(sessionStorage[ 'videoWatchRecords' + this.courseId ])) ||
          {}
      let level = this.currentCourse.video_duration * 0.98
      if (watchRecordsObj[ this.currentCourse.id ]) {
        watchRecordsObj[ this.currentCourse.id ] =
            watchRecordsObj[ this.currentCourse.id ].time >= level
                ? { time: lastSecond, is_finished: true }
                : { time: lastSecond, is_finished: false }
      } else {
        watchRecordsObj[ this.currentCourse.id ] = {
          time: lastSecond,
          is_finished: false,
        }
      }
      let oldWatchVideoRecord = watchRecordsObj[ this.currentCourse.id ]
      sessionStorage[ 'videoWatchRecords' + this.courseId ] =
          JSON.stringify(watchRecordsObj)
      if (this.intervalObj) {
        clearInterval(this.intervalObj)
        let params = {
          last_second: lastSecond,
          duration: this.videoDruation,
        }
        classesCourseWatchDuration(this.currentCourseId, params, () => {
          this.videoDruation = 0
          this.intervalObj = null
        })
      }
      this.playFlag = true
      this.currentVideoFid = item.video_id
      this.videoUrl = item.video_url
      this.currentCourseId = item.id
      this.currentCourse = item

      this.courseDetailData.forEach(( ll, index ) => {
        ll.children.forEach(( course, courseIndex ) => {
          if (course.id === oldCourseId) {
            console.log(
                course.id,
                this.courseDetailData[ index ].children[ courseIndex ]
            )
            let obj = { ...course }
            obj.last_second = oldWatchVideoRecord.time
            obj.is_finished = obj.is_finished
                ? true
                : oldWatchVideoRecord.is_finished

            this.$set(this.courseDetailData[ index ].children, courseIndex, obj)
          }
        })
      })
      // this.courseDetailData = Object.assign({}, this.courseDetailData);
    },
    buyCourse () {
      this.setCurrentCourse({ course: { ...this.courseDetailData } })
      if (this.btnName === '立即学习') {
        this.$router.push({
          path: `/user/pastCourseDetail/${this.courseId}/${this.currentPlanId}`,
        })
      } else {
        Api.buyUserCourse(this.courseId, ( res ) => {
          this.$message.success('报名成功')
          this.$router.push({
            path: `/user/pastCourseDetail/${this.courseId}/${res.id}`,
          })
        })
      }
    },
    onPlayerPlay () {
      if (this.playFlag) {
        courseWatch(this.currentCourseId, () => {
          console.log('统计成功')
          this.playFlag = false
        })
      }
      this.intervalObj = setInterval(() => {
        console.log(this.videoDruation)
        this.videoDruation += 1
      }, 1000)
    },
    onPlayerPause () {
      const lastSecond = Math.floor(this.$refs.childplayer.getCurrentTime())
      let params = {
        last_second: lastSecond,
        duration: this.videoDruation,
      }
      classesCourseWatchDuration(this.currentCourseId, params, () => {
        clearInterval(this.intervalObj)
        this.videoDruation = 0
        this.intervalObj = null
      })
    },
    // todo 免费视频跳知乎
    // getPlayId(id) {
    //   Api.getPlayId(id, (res) => {
    //     console.log(res);
    //     window.open('https://papaen.zhihu.com/' + res.content);
    //   });
    // },
    ...mapActions(['setCurrentCourse']),
  },
  beforeRouteEnter ( to, from, next ) {
    next(( vm ) => {
      vm.timeCount = 0
      vm.timeInterval = setInterval(() => {
        vm.timeCount++
      }, 1000)
    })
  },
  beforeRouteLeave ( to, from, next ) {
    clearInterval(this.timeInterval)
    console.log()
    let params = {
      duration: this.timeCount,
      type: ANALYTIC_TYPE.EXIT,
    }

    //  小于零表示是从首页点击进入的  需要统计  大于零表示从我的课程进入不需要统计
    if (this.$route.path.indexOf('user') < 0) {
      courseAnalystic(this.courseId, params, () => {
      })
    } else {
      const lastSecond = Math.floor(this.$refs.childplayer.getCurrentTime())
      if (this.intervalObj) {
        clearInterval(this.intervalObj)
        let params = {
          last_second: lastSecond,
          // course_id: this.$route.params.id,
          duration: this.videoDruation,
        }
        classesCourseWatchDuration(this.currentCourseId, params, () => {
          this.videoDruation = 0
          this.intervalObj = null
        })
      }
    }
    if (this.videoUrl !== '') {
      // todo 免费视频跳知乎
      this.$refs.childplayer.distory()
    }
    next()
  },
  watch: {
    $route ( newVal, oldVal ) {
      if (newVal !== oldVal) {
        if (this.$route.path.indexOf('user') > 0) {
          this.isBuy = 2
        }
        this.getCourseDetail()
        if (this.isBuy === 2) {
          this.activeName = 'schedule'
          this.handleClick({ name: 'schedule' })
        }
      }
    },
  },
}
