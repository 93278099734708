<template>
  <div class="course-material">
    <div v-if="materials.length>0" class="materials">
      <div class="papa-collapse">
        <div class="collapse-item" v-for="item in materials" :key="item.name">
          <div class="title-item" @click="handleCollapse(item.name)">
            <div class="title">{{ item.name }}</div>
            <div class="icon" :class="activePanel.includes(item.name)?'icon-active':''">
              <i class="iconfont icon-papa-coupon_arrow" :class="activePanel.includes(item.name)?'icon-papa-coupon_arrow-active':''"></i>
            </div>
          </div>
          <el-collapse-transition>
            <div class="list-item" v-show="activePanel.includes(item.name)">
              <div class="courseware" v-for="courseware in item.coursewares" :key="courseware.file_path" >
                <div class="left">
                  <div class="icon">
                    <img :src="`${imgDomain}icons/${courseware.file_type}.png`" alt="">
                  </div>

                  <span class="name">{{courseware.filename}}.{{courseware.file_type}}</span>
                </div>
                <div class="content">
                  <span class="create-time">{{formatMoment(courseware.created_at)}}</span>
                  <span class="file-size" v-if="courseware.file_size > 1048576">{{(courseware.file_size/1048576).toFixed(1)}}M</span>
                  <span class="file-size" v-else>{{(courseware.file_size/1024).toFixed(1)}}K</span>
                </div>
                <div class="right">
                  <div class="button" @click="handleDownload(courseware)">下载</div>
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>

    </div>
    <NoContent v-else :data="noContentData"></NoContent>
  </div>
</template>

<script>
  import NoContent from '@/components/NoContent/NoContent.vue'
  import { formatMoment } from '@/utils/utils'
  import { Message } from 'element-ui'
  import * as Api from '@/api/user/user'

  export default {
    components: {
      NoContent
    },
    name: 'CourseMaterial',
    props: {
      materials: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    data () {
      return {
        noContentData: {
          pic: 'evaluation_pic',
          tip: '没有课程资料'
        },
        activePanel: [],
        imgDomain: process.env.VUE_APP_IMAGE_DOMAIN
      }
    },
    methods: {
      handleCollapse(panel){
       let targetIndex =  this.activePanel.findIndex(item => item === panel)
        if(targetIndex > -1){
            this.activePanel.splice(targetIndex, 1)
        }else{
          this.activePanel.push(panel)
        }
      },
      handleDownload(material){
        Api.getCourseMaterialUrl(material.id,(res)=>{
          window.open(res.url)
        },(err)=>{
          console.log(err)
          Message.error(err.data.message)
        })
      },
      handleChange () {
      },
      jumpPage ( src ) {
        window.open(src)
      },
      formatMoment
    }
  }
</script>

<style lang="less" scoped>
  .course-material {
    width: 100%;
    & > .materials {
      .papa-collapse {
        width: 100%;
        .collapse-item {
          width: 100%;
          .title-item {
            box-sizing: border-box;
            padding: 0 30px;
            display: flex;
            align-items: center;
            height: 56px;
            justify-content: space-between;
            border-bottom: 1px solid #EBEEF5;
            position: relative;
            .title {
              color: #333;
              font-size: 18px;
              font-weight: bold;
            }
            .icon{
              width: 20px;
              height: 20px;
              cursor: pointer;
              position: absolute;
              right: 30px;
              .iconfont:before{
                margin-right: 0;
              }
            }
            .icon-active{
              width: 20px;
              height: 20px;
              transform: rotateZ(90deg);
            }

          }
          .list-item{
            .courseware {
              box-sizing: border-box;
              padding: 0 30px;
              border-bottom: 1px solid #EBEEF5;
              display: flex;
              align-items: center;
              height: 56px;
              width: 100%;

              .left, .content, .right {
                flex-shrink: 0;
              }

              .left {
                width: 450px;
                color: rgba(0, 0, 0, 0.7);
                text-align: left;
                display: flex;
                font-size: 14px;
                .icon{
                  width: 20px;
                  margin-right:12px;
                  img{
                    width: 100%;
                  }
                }
              }

              .content {
                width: 230px;
                text-align: left;
                font-size: 14px;
                color: rgba(0,0,0,0.6);
                .create-time {
                  margin-right: 10px;
                }
              }

              .right {
                flex: 1;
                font-size: 12px;
                text-align: right;
                color: #29d087;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
</style>
