var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"courseDetail"},[(_vm.courseDetailType == 1)?_c('div',{staticClass:"courseCard pageContent"},[_c('div',{staticClass:"gallery"},[_c('img',{staticClass:"courseImage",attrs:{"src":_vm.courseDetailData.cover_image_url,"alt":""}})]),_c('div',{staticClass:"property"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"detail-hd"},[_vm._v(" "+_vm._s(_vm.courseDetailData.name)+" ")])]),(false)?_c('div',{staticClass:"key"},[_c('div',{staticClass:"course-subject"},[_c('div',{staticClass:"key"},[_vm._v("科目选择")]),_c('div',{staticClass:"value"},[(_vm.courseDetailData.attributes)?_c('span',{staticClass:"active"},[_vm._v(_vm._s(_vm.courseDetailData.attributes[0].name))]):_vm._e()])]),(
              _vm.courseDetailData.attributes &&
              _vm.courseDetailData.attributes[0] &&
              _vm.courseDetailData.attributes[0].teacher_level
            )?_c('div',{staticClass:"teacher-level"},[_c('div',{staticClass:"key"},[_vm._v("教师级别")]),_c('div',{staticClass:"value"},[(_vm.courseDetailData.attributes[0].teacher_level)?_c('span',{staticClass:"active"},[_vm._v(_vm._s(_vm.courseDetailData.attributes[0].teacher_level[0].name))]):_vm._e()])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"action"},[_vm._m(0),_c('div',{staticClass:"buy-course"},[_c('div',{staticClass:"btn-buy",on:{"click":_vm.buyCourse}},[_vm._v(_vm._s(_vm.btnName))])])])])]):_c('div',{staticClass:"personal pageContent"},[_c('div',{staticClass:"galleryVideo"},[_c('TCPlayer',{ref:"childplayer",staticClass:"player-size",attrs:{"course":_vm.currentCourse,"courseId":_vm.courseId,"fid":_vm.currentVideoFid},on:{"onPlayerPlay":_vm.onPlayerPlay,"onPlayerPause":_vm.onPlayerPause}})],1),_c('div',{staticClass:"course-list"},_vm._l((_vm.courseDetailData),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(item.title))]),_vm._l((item.children),function(course,index){return _c('div',{key:index,staticClass:"item-course",class:_vm.currentCourseId === course.id ? 'active' : '',on:{"click":function($event){return _vm.changeVideo(course)}}},[_c('div',{staticClass:"tag",class:_vm.currentCourseId === course.id ? 'active' : ''},[_vm._v(" "+_vm._s(course.course_mode === _vm.COURSE_MODEL.VIDEO ? "视频" : "录播")+" ")]),_c('div',{staticClass:"course-con"},[_c('div',{staticClass:"course-name",class:_vm.currentCourseId === course.id ? 'active' : ''},[_vm._v(" "+_vm._s(course.title)+" ")]),(course.is_finished)?[_c('div',{staticClass:"course-finished"},[_vm._v("已学完")])]:[[(course.last_second === 0)?_c('div',{staticClass:"course-progress"}):(
                      Math.floor(
                        ((course.last_second * 100) /
                          (course.video_duration * 100)) *
                          100
                      ) < 0.01 && course.last_second !== 0
                    )?_c('div',{staticClass:"course-progress"},[_vm._v(" 学习不足1% ")]):(
                      Math.ceil(
                        ((course.last_second * 100) /
                          (course.video_duration * 100)) *
                          100
                      ) >= 98
                    )?_c('div',{staticClass:"course-finished"},[_vm._v(" 已学完 ")]):_c('div',{staticClass:"course-progress"},[_vm._v(" 已学至"+_vm._s(Math.floor( ((course.last_second * 100) / (course.video_duration * 100)) * 100 ))+"% ")])]]],2)])})],2)}),0)]),(_vm.courseDetailType == 2 && _vm.courseDesc.course)?_c('div',{staticClass:"bottom-title"},[_vm._v(" "+_vm._s(_vm.courseDesc.course.name)+" ")]):_vm._e(),_c('div',{staticClass:"courseRelated"},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[(_vm.courseDetailType == 1)?_c('el-tab-pane',{attrs:{"label":"课程简介","name":"detail"}},[_c('img',{attrs:{"src":_vm.courseDetailData.introduction_image_url,"alt":""}})]):_vm._e(),_c('el-tab-pane',{attrs:{"label":"课程表","name":"schedule"}},[_c('ClassSchedule',{attrs:{"classSchedule":_vm.classSchedule}})],1),(_vm.courseDetailType == 2)?_c('el-tab-pane',{attrs:{"label":"课程资料","name":"materials"}},[_c('CourseMaterial',{attrs:{"materials":_vm.CourseMaterialData}})],1):_vm._e(),_c('el-tab-pane',{attrs:{"label":"相关课程","name":"relation"}},[_c('RelationCourse',{attrs:{"relationData":_vm.relationCourse}})],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"course-price"},[_c('div',{staticClass:"key"},[_vm._v("课程价格")]),_c('div',{staticClass:"price"},[_c('span',[_vm._v("免费")])])])
}]

export { render, staticRenderFns }