<template>
  <div class="courseDetail">
    <div v-if="courseDetailType == 1" class="courseCard pageContent">
      <div class="gallery">
        <img
          :src="courseDetailData.cover_image_url"
          class="courseImage"
          alt=""
        />
      </div>
      <div class="property">
        <div class="title">
          <div class="detail-hd">
            {{ courseDetailData.name }}
          </div>
        </div>
        <div class="key" v-if="false">
          <div class="course-subject">
            <div class="key">科目选择</div>
            <div class="value">
              <span class="active" v-if="courseDetailData.attributes">{{
                courseDetailData.attributes[0].name
              }}</span>
            </div>
          </div>
          <div
            class="teacher-level"
            v-if="
              courseDetailData.attributes &&
              courseDetailData.attributes[0] &&
              courseDetailData.attributes[0].teacher_level
            "
          >
            <div class="key">教师级别</div>
            <div class="value">
              <span
                class="active"
                v-if="courseDetailData.attributes[0].teacher_level"
                >{{
                  courseDetailData.attributes[0].teacher_level[0].name
                }}</span
              >
            </div>
          </div>
        </div>
        <div class="action">
          <div class="course-price">
            <div class="key">课程价格</div>
            <div class="price"><span>免费</span></div>
          </div>
          <div class="buy-course">
            <div class="btn-buy" @click="buyCourse">{{ btnName }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="personal pageContent">
      <!--      <div v-if="videoUrl === ''" class="notBegin">-->
      <!--        <div>该课程还未开始</div>-->
      <!--        <br />-->
      <!--        <div>({{ currentCourse.time }})</div>-->
      <!--      </div>-->
      <div class="galleryVideo">
        <!--          todo 免费视频跳知乎 -->
        <TCPlayer
          class="player-size"
          ref="childplayer"
          :course="currentCourse"
          :courseId="courseId"
          :fid="currentVideoFid"
          @onPlayerPlay="onPlayerPlay"
          @onPlayerPause="onPlayerPause"
        ></TCPlayer>
<!--        <div class="galleryVideo">-->
<!--          <img :src="postPic" alt="" />-->
<!--        </div>-->
      </div>
      <div class="course-list">
        <div
          class="item"
          v-for="(item, index) in courseDetailData"
          :key="index"
        >
          <div class="item-title">{{ item.title }}</div>
          <div
            class="item-course"
            v-for="(course, index) in item.children"
            :class="currentCourseId === course.id ? 'active' : ''"
            @click="changeVideo(course)"
            :key="index"
          >
            <div
              class="tag"
              :class="currentCourseId === course.id ? 'active' : ''"
            >
              {{ course.course_mode === COURSE_MODEL.VIDEO ? "视频" : "录播" }}
            </div>
            <div class="course-con">
              <div
                class="course-name"
                :class="currentCourseId === course.id ? 'active' : ''"
              >
                {{ course.title }}
              </div>
              <template v-if="course.is_finished">
                <div class="course-finished">已学完</div>
              </template>
              <template v-else>
                <template>
                  <div
                    class="course-progress"
                    v-if="course.last_second === 0"
                  ></div>
                  <div
                    class="course-progress"
                    v-else-if="
                      Math.floor(
                        ((course.last_second * 100) /
                          (course.video_duration * 100)) *
                          100
                      ) < 0.01 && course.last_second !== 0
                    "
                  >
                    学习不足1%
                  </div>
                  <div
                    class="course-finished"
                    v-else-if="
                      Math.ceil(
                        ((course.last_second * 100) /
                          (course.video_duration * 100)) *
                          100
                      ) >= 98
                    "
                  >
                    已学完
                  </div>
                  <div class="course-progress" v-else>
                    已学至{{
                      Math.floor(
                        ((course.last_second * 100) /
                          (course.video_duration * 100)) *
                          100
                      )
                    }}%
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="courseDetailType == 2 && courseDesc.course" class="bottom-title">
      {{ courseDesc.course.name }}
    </div>
    <div class="courseRelated">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          label="课程简介"
          name="detail"
          v-if="courseDetailType == 1"
        >
          <img :src="courseDetailData.introduction_image_url" alt="" />
        </el-tab-pane>
        <el-tab-pane label="课程表" name="schedule">
          <ClassSchedule :classSchedule="classSchedule"></ClassSchedule>
        </el-tab-pane>
        <el-tab-pane
          label="课程资料"
          name="materials"
          v-if="courseDetailType == 2"
        >
          <CourseMaterial :materials="CourseMaterialData"></CourseMaterial>
        </el-tab-pane>
        <!--        <el-tab-pane label="评价" name="comment">-->
        <!--          <Comment :isComment="courseDesc.is_comment" v-if="activeName==='comment'"></Comment>-->
        <!--        </el-tab-pane>-->
        <el-tab-pane label="相关课程" name="relation">
          <RelationCourse :relationData="relationCourse"></RelationCourse>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import PastCourseDetailJs from "./PastCourseDetail.js";

export default PastCourseDetailJs;
</script>

<style lang="less" scoped>
/*@import "../../assets/style/lib-base.less";*/

.courseDetail {
  width: 100%;
  // position: absolute;
  top: 100px;
  left: 0;
  z-index: 998;
  margin-top: 17px;
  background: #fff;
  min-height: 100vh;
  margin-bottom: 30px;

  .courseCard,
  & > .personal {
    .pageContent;
    margin: 0 auto;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0px 2px 6px 0px rgba(95, 95, 95, 0.07);
    display: flex;
    justify-content: space-between;

    .gallery {
      width: 62.5%;
      padding: 14px;
      min-height: 380px;
      box-sizing: border-box;

      img,
      video {
        width: 100%;
      }
    }

    & > .notBegin {
      background: #000;
      width: 75%;
      height: 405px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }

    & > .galleryVideo {
      width: 75%;
      height: 405px;
      box-sizing: border-box;

      img,
      video {
        width: 100%;
        height: 100%;
      }
    }

    .property {
      width: 36%;
      box-shadow: 0px -7px 16px 0px rgba(95, 95, 95, 0.07);
      padding: 14px;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 26px;
        color: #333;
        font-weight: bold;
        margin-top: 6px;
        text-align: left;
        min-height: 20%;

        .detail-hd {
          font-size: @fontSizeTitle;
          color: #333;
          font-weight: 800;
        }

        .detail-agreement {
          cursor: pointer;
          color: #999;
          font-size: @fontSizeCon;
          padding: 14px 0 17px;
        }
      }

      & > .key {
        padding-bottom: 20px;
        min-height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .course-subject,
        .teacher-level,
        .course-amount {
          padding-top: 14px;
          display: flex;
          font-size: @fontSizeCon;

          .key {
            color: #333;
            margin-right: 14px;
            line-height: 30px;
            min-width: 48px;
          }

          .value {
            width: calc(100% - 62px);

            span {
              border: 1px solid #d0d0d0;
              cursor: pointer;
              display: inline-block;
              padding: 5px 9px;
              border-radius: 3px;
              margin: 0 8px 5px 0;
              float: left;
            }

            .active {
              border: 1px solid @papaGreen;
              color: @papaGreen;
            }
          }
        }
      }

      & > .action {
        min-height: 30%;

        .course-price {
          padding-top: 14px;
          display: flex;
          font-size: @fontSizeCon;
          margin-bottom: 20px;

          .key {
            color: #333;
            margin-right: 14px;
            line-height: 40px;
            min-width: 48px;
          }

          .price {
            color: @papaRed;

            span {
              font-size: @fontSizePrice;
            }
          }
        }

        .buy-course {
          display: flex;

          .btn-buy {
            .btnGreen(170px, 36px, 2px);
            margin-right: 17px;
          }

          .btn-basket {
            cursor: pointer;
            width: 100px;
            height: 36px;
            line-height: 36px;
            color: #fe6f76;
            border: 1px solid @papaRed;
            border-radius: 2px;
            box-sizing: border-box;
          }
        }
      }
    }

    & > .course-list {
      width: 25%;
      height: 405px;
      overflow-y: auto;
      background: #272727;
      box-sizing: border-box;
      padding: 0px 16px;

      & > .item {
        color: #fff;
        text-align: left;
        border-bottom: 1px solid #333333;
        padding: 20px 0;

        & > .item-title {
          font-weight: bold;
          font-size: @fontSizeSubTitle;
          margin-bottom: 14px;
        }

        & > .item-course {
          display: flex;
          margin-bottom: 14px;
          cursor: pointer;

          & > .tag {
            width: 32px;
            min-width: 2.5em;
            height: 18px;
            border-radius: 3px;
            background: #4d4d4d;
            font-size: @fontSizeCon;
            color: #e6e6e6;
            text-align: center;
            margin-right: 10px;
            margin-top: 4px;
          }

          & > .active {
            color: #fff;
            background: @papaGreen;
          }

          & > .course-con {
            width: 100%;
            display: flex;
            //flex-direction: column;
            color: #ccc;

            & > .course-name {
              width: 6em;
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 12px;
              white-space: nowrap;
            }
            .course-progress {
              font-size: 12px;
            }
            .course-finished {
              font-size: 12px;
              width: 4em;
              background: #404040;
              color: #fff;
              height: 1.5em;
              text-align: center;
              line-height: 1.5em;
              border-radius: 8px;
            }

            & > .active {
              color: @papaGreen !important;
            }
          }
        }

        & > .active {
          color: @papaGreen !important;
        }
      }
    }
  }

  .bottom-title {
    .pageContent;
    width: 100%;
    height: 80px;
    padding: 28px 22px;
    box-sizing: border-box;
    color: #fff;
    font-size: @fontSizePrice;
    background: #404040;
    text-align: left;
  }

  .courseRelated {
    .pageContent;
    margin: 20px auto;
    padding: 24px;
    background: #fff;
    /deep/.course-material{
      .title-item{
        .title{
          font-size: 13px!important;
        }
      }
    }
  }

  .visibility_hidden {
    visibility: hidden;
  }
}
</style>
